import { useMemo } from 'react';
import Link from './link';
import Header from './header';
import UptimeRobot from './uptimerobot';
import Package from '../../package.json';

function App() {

  const apikeys = useMemo(() => {
    const { ApiKeys } = window.Config;
    if (Array.isArray(ApiKeys)) return ApiKeys;
    if (typeof ApiKeys === 'string') return [ApiKeys];
    return [];
  }, []);

  return (
    <>
      <Header />
      <div className='container'>
        <div id='uptime'>
          {apikeys.map((key) => (
            <UptimeRobot key={key} apikey={key} />
          ))}
        </div>
        <div id='footer'>
          <p class="ip"></p>
          <p class="copyright">&copy; 2023 <Link to='https://sourcedream.cn' text='源梦&百川' /> ALL RIGHTS RESERVED.</p>
          <p class="beian"></p>
        </div>
	  <script src="https://cdn.wulibaichuan.com/js/corp2.js"></script>
        <script async src="https://cdn.wulibaichuan.com/js/ip.jiami.js"></script>
      </div>
    </>
  );
}

export default App;
